// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-convertfly-js": () => import("./../../../src/pages/blogs/convertfly.js" /* webpackChunkName: "component---src-pages-blogs-convertfly-js" */),
  "component---src-pages-blogs-reslash-js": () => import("./../../../src/pages/blogs/reslash.js" /* webpackChunkName: "component---src-pages-blogs-reslash-js" */),
  "component---src-pages-blogs-skillsalpha-js": () => import("./../../../src/pages/blogs/skillsalpha.js" /* webpackChunkName: "component---src-pages-blogs-skillsalpha-js" */),
  "component---src-pages-blogs-visualgator-js": () => import("./../../../src/pages/blogs/visualgator.js" /* webpackChunkName: "component---src-pages-blogs-visualgator-js" */),
  "component---src-pages-blogs-yoola-js": () => import("./../../../src/pages/blogs/yoola.js" /* webpackChunkName: "component---src-pages-blogs-yoola-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

